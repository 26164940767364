body {
  background-color: #f0f2f7;
  font-family: 'Exo 2', sans-serif;
 }

.carousel {
  text-align: center;
  width: 100%;
}

.home-links{
  padding-top: 4em;
  padding-bottom: 5em;
  font-weight: 600;
  text-align: center;
}

.home-links-text{
  padding-bottom: 2em;
}

.bio-text {
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 2em;
  font-size: 14pt;
  font-weight: 600;
  text-align: left;
}

.contact {
  margin-top: 5em;
  padding: 2em;
  text-align: center;
}

.projects {
  margin-top: 1em;
  margin-left: 10em;
  width: 80%;
  text-align: left;
}

#project-title {
  color: crimson;
  text-decoration: none;
}

.project-description {
  margin-left: 2em;
}

.navbar-brand {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 3px;
  display: block;
  padding: 15px 20px 18px;
}

.nav-link {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 5px;
  display: block;
  padding: 15px 20px 18px;
}